<template>
  <div>
    <div id="viewDiv" class="balt-theme">
      <div class="localities"></div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { minutesToHours } from "date-fns";
import { loadModules } from "esri-loader";
import { mapGetters } from "vuex";

export default {
  name: "Map",
  data() {
    return {
      view: null,
      legend: null,
      nucleosAgrarios: null,
      levelOne: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: "#edf8e9",
        style: "solid",
        outline: {
          width: 0.4,
          color: "#808080",
        },
      },
      levelTwo: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: "#bae4b3",
        style: "solid",
        outline: {
          width: 0.4,
          color: "#808080",
        },
      },
      levelThree: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: "#74c476",
        style: "solid",
        outline: {
          width: 0.4,
          color: "#808080",
        },
      },
      levelFour: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: "#31a354",
        style: "solid",
        outline: {
          width: 0.4,
          color: "#808080",
        },
      },
      levelFive: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: "#006d2c",
        style: "solid",
        outline: {
          width: 0.4,
          color: "#808080",
        },
      },
      myPointsLocalitiesSchemes: [
        {
          site: "Centros penitenciarios",
          url:"https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/dist_centros_penitenciarios_v2_mas_quintana_roo___dist_centros_penitenciarios_v2/FeatureServer",
          //url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/dist_centros_penitenciarios_v2/FeatureServer/0",
          popupTitle: "Municipio: <p>{nom_mun}</p> Localidad: <p>{nom_loc}</p>",
          renderer: {
            "visualVariables": [
              {
                "type": "color",
                "field": "distancia",
                "valueExpression": null,
                "stops": [
                  {
                    "value": 20576,
                    "color": [
                      255,
                      255,
                      178,
                      255
                    ],
                    "label": "< 20,576"
                  },
                  {
                    "value": 39583,
                    "color": [
                      254,
                      204,
                      92,
                      255
                    ],
                    "label": null
                  },
                  {
                    "value": 58590,
                    "color": [
                      253,
                      141,
                      60,
                      255
                    ],
                    "label": "58,590"
                  },
                  {
                    "value": 77597,
                    "color": [
                      240,
                      59,
                      32,
                      255
                    ],
                    "label": null
                  },
                  {
                    "value": 96605,
                    "color": [
                      189,
                      0,
                      38,
                      255
                    ],
                    "label": "> 96,605"
                  }
                ]
              }
            ],
            type: "class-breaks",
            field: "distancia",
            /* legendOptions: {
              title: "Habitantes totales del municipio (habs)",
            }, */
            "authoringInfo":{"visualVariables":[{"type":"color","minSliderValue":0,"maxSliderValue":90326.7904,"theme":"high-to-low"}]},
            "classBreakInfos":[
              {"symbol":
                { "color":[170,170,170,255],
                  "size":4.5,
                  "angle":0,
                  "xoffset":0,
                  "yoffset":0,
                  "type":"simple-marker",
                  "style":"circle",
                  "outline":{"color":[194,194,194,64],"width":0.375,"type":"simple-line","style":"solid"}},
                  "classMaxValue":9007199254740991
              }]
          },
          "transparency": 20
        },
        {
          site: "Ministerios públicos federales",
          url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/dist_ministerios_pu_blicos_federales_v2/FeatureServer/0",
          popupTitle: "Municipio: <p>{nom_mun}</p> Localidad: <p>{nom_loc}</p>",
        },
        {
          site: "Ministerios públicos estatales",
          url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/dist_ministerios_pu_blicos_estatales_v2/FeatureServer/0",
          popupTitle: "Municipio: <p>{nom_mun}</p> Localidad: <p>{nom_loc}</p>",
        },
        {
          site: "Tribunales y juzgados estatales",
          url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/dist_tribunales_y_juzgados_estatales_v2/FeatureServer/0",
          popupTitle: "Municipio: <p>{nom_mun}</p> Localidad: <p>{nom_loc}</p>",
        },
        {
          site: "Tribunales y juzgados federales",
          url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/dist_tribunales_y_juzgados_federales_v2/FeatureServer/0",
          popupTitle: "Municipio: <p>{nom_mun}</p> Localidad: <p>{nom_loc}</p>",
        },
      ],
      mySitesLayerSchemes: [
        {
          title: "Centros penitenciarios",
          url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/centros_penitenciarios_mx_mas_quintanaroo___centros_penitenciarios_mx/FeatureServer",
          //url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/centros_penitenciarios_mx/FeatureServer/0",
          popupTitle: "Centro Penitenciario:" + "<p>{categoria}</p>",
          definitionExpression: "",
        },
        {
          title: "Ministerios públicos federales",
          url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/ministerios_publicos_mx_mas_quintanarro___ministerios_publicos_mx/FeatureServer",
          //url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/ministerios_publicos/FeatureServer/0",
          popupTitle: "Ministerio público:" + "<p>{categoria}</p>",
          definitionExpression: "categoria = 'Ministerio Público Federal'",
        },
        {
          title: "Ministerios públicos estatales",
          url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/ministerios_publicos_mx_mas_quintanarro___ministerios_publicos_mx/FeatureServer",
          //url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/ministerios_publicos/FeatureServer/0",
          popupTitle:
            "Ministerio público:" + "<p>{categoria}</p>" + "<p>{tipo}</p>",
          definitionExpression:
            "categoria = 'Agencia del Ministerio Público de la Entidad'",
        },
        {
          title: "Tribunales y juzgados estatales",
          url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/juzgados_del_estado_v2_mas_quintanaroo___juzgados_del_estado_v2/FeatureServer",
          //url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/juzgados_del_estado_v2/FeatureServer/0",
          popupTitle: "<p>{categoria}</p>",
          definitionExpression:
            "categoria = 'Tribunal o Juzgado del Poder Judicial de Estado'",
        },
        {
          title: "Tribunales y juzgados federales",
          url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/juzgados_del_estado_v2_mas_quintanaroo___juzgados_del_estado_v2/FeatureServer",
          //url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/juzgados_del_estado_v2/FeatureServer/0",
          popupTitle: "<p>{categoria}</p>",
          definitionExpression:
            "categoria = 'Tribunal o Juzgado del Poder Judicial de la Federación'",
        },
        {
          title: "Justicia para adolescentes",
          url:"https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/ubicacio%CC%81n_justicia_adolescentes_mas_quintana_roo___Hoja1/FeatureServer",
          //url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/05_Ubicacio%CC%81n_Justicia_Adolescentes_xlsx___Hoja1/FeatureServer",
          popupTitle: "<p>{categoria}</p><p>{entidad}</p>",
          definitionExpression: "",
        },
        {
          title: "Juzgados de atención a población indígena",
          url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/07_Juzgados_atencio%CC%81n_PI_xlsx___Juzgados/FeatureServer",
          popupTitle: "<p>{categoria}</p><p>{entidad}</p><p>{nom_mun}</p><p>{localidad}</p>",
          definitionExpression: "",
          renderer:{
            "type": "unique-value",
            "field": "Categoria",
            "uniqueValueGroups": [
              {
                "classes": [
                  {
                    "label": "Juzgado de Paz",
                    "symbol": {
                      "type": "simple-marker",
                      "color": [
                        237,
                        81,
                        81,
                        255
                      ],
                      "angle": 0,
                      "xoffset": 0,
                      "yoffset": 0,
                      "size": 16,
                      "style": "circle",
                      "outline": {
                        "type": "simple-line",
                        "color": [
                          153,
                          153,
                          153,
                          64
                        ],
                        "width": 0.75,
                        "style": "solid"
                      }
                    },
                    "values": [
                      [
                        "Juzgado de Paz"
                      ]
                    ]
                  },
                  {
                    "label": "Juzgado de Conciliación",
                    "symbol": {
                      "type": "simple-marker",
                      "color": [
                        20,
                        158,
                        206,
                        255
                      ],
                      "size": "6px",
                      "style": "circle",
                      "outline": {
                        "type": "simple-line",
                        "color": [
                          153,
                          153,
                          153,
                          64
                        ],
                        "width": 0.75,
                        "style": "solid"
                      }
                    },
                    "values": [
                      [
                        "Juzgado de Conciliación"
                      ]
                    ]
                  },
                  {
                    "label": "Juzgado Maya",
                    "symbol": {
                      "type": "simple-marker",
                      "color": [
                        167,
                        198,
                        54,
                        255
                      ],
                      "angle": 0,
                      "xoffset": 0,
                      "yoffset": 0,
                      "size": 6,
                      "style": "circle",
                      "outline": {
                        "type": "simple-line",
                        "color": [
                          153,
                          153,
                          153,
                          64
                        ],
                        "width": 0.75,
                        "style": "solid"
                      }
                    },
                    "values": [
                      [
                        "Juzgado Maya"
                      ]
                    ]
                  },
                  {
                    "label": "Sala de Justicia Indígena y Quinta Sala Penal",
                    "symbol": {
                      "type": "simple-marker",
                      "color": [
                        158,
                        85,
                        156,
                        255
                      ],
                      "angle": 0,
                      "xoffset": 0,
                      "yoffset": 0,
                      "size": 6,
                      "style": "circle",
                      "outline": {
                        "type": "simple-line",
                        "color": [
                          153,
                          153,
                          153,
                          64
                        ],
                        "width": 0.75,
                        "style": "solid"
                      }
                    },
                    "values": [
                      [
                        "Sala de Justicia Indígena y Quinta Sala Penal"
                      ]
                    ]
                  }
                ]
              }
            ],
            "uniqueValueInfos": [
              {
                "label": "Juzgado de Paz",
                "symbol": {
                  "type": "simple-marker",
                  "color": [
                    237,
                    81,
                    81,
                    255
                  ],
                  "angle": 0,
                  "xoffset": 0,
                  "yoffset": 0,
                  "size": 6,
                  "style": "circle",
                  "outline": {
                    "type": "simple-line",
                    "color": [
                      153,
                      153,
                      153,
                      64
                    ],
                    "width": 0.75,
                    "style": "solid"
                  }
                },
                "value": "Juzgado de Paz"
              },
              {
                "label": "Juzgado de Conciliación",
                "symbol": {
                  "type": "simple-marker",
                  "color": [
                    20,
                    158,
                    206,
                    255
                  ],
                  "angle": 0,
                  "xoffset": 0,
                  "yoffset": 0,
                  "size": 6,
                  "style": "circle",
                  "outline": {
                    "type": "simple-line",
                    "color": [
                      153,
                      153,
                      153,
                      64
                    ],
                    "width": 0.75,
                    "style": "solid"
                  }
                },
                "value": "Juzgado de Conciliación"
              },
              {
                "label": "Juzgado Maya",
                "symbol": {
                  "type": "simple-marker",
                  "color": [
                    167,
                    198,
                    54,
                    255
                  ],
                  "angle": 0,
                  "xoffset": 0,
                  "yoffset": 0,
                  "size": 6,
                  "style": "circle",
                  "outline": {
                    "type": "simple-line",
                    "color": [
                      153,
                      153,
                      153,
                      64
                    ],
                    "width": 0.75,
                    "style": "solid"
                  }
                },
                "value": "Juzgado Maya"
              },
              {
                "label": "Sala de Justicia Indígena y Quinta Sala Penal",
                "symbol": {
                  "type": "simple-marker",
                  "color": [
                    158,
                    85,
                    156,
                    255
                  ],
                  "angle": 0,
                  "xoffset": 0,
                  "yoffset": 0,
                  "size": 6,
                  "style": "circle",
                  "outline": {
                    "type": "simple-line",
                    "color": [
                      153,
                      153,
                      153,
                      64
                    ],
                    "width": 0.75,
                    "style": "solid"
                  }
                },
                "value": "Sala de Justicia Indígena y Quinta Sala Penal"
              },
              {
                "label": "Juzgado Tradicional",
                "symbol": {
                  "type": "simple-marker",
                  "color": [
                    167,
                    198,
                    54,
                    255
                  ],
                  "angle": 0,
                  "xoffset": 0,
                  "yoffset": 0,
                  "size": 6,
                  "style": "circle",
                  "outline": {
                    "type": "simple-line",
                    "color": [
                      153,
                      153,
                      153,
                      64
                    ],
                    "width": 0.75,
                    "style": "solid"
                  }
                },
                "value": "JUZGADO TRADICIONAL"
              },
              {
                "label": "Magistratura de Asuntos Indigenas",
                "symbol": {
                  "type": "simple-marker",
                  "color": [
                    252,
                    146,
                    31,
                    255
                  ],
                  "angle": 0,
                  "xoffset": 0,
                  "yoffset": 0,
                  "size": 6,
                  "style": "circle",
                  "outline": {
                    "type": "simple-line",
                    "color": [
                      153,
                      153,
                      153,
                      64
                    ],
                    "width": 0.75,
                    "style": "solid"
                  }
                },
                "value": "MAGISTRATURA DE ASUNTOS INDIGENAS"
              }
            ]
          }
        },
      ],
      mySitesLayer: null,
      searchLocalityLayer: null,
      myPointsLocalities: null,
      FeatureLayer: null,
      indicatorsLayer: null,
      runningQuery: false,
      route: null,
      searchedPoint: null,
      originPoint: null,
      destinationPoint: null,
      map: null,
      localities: null,
      routeUrl:
        "https://route-api.arcgis.com/arcgis/rest/services/World/Route/NAServer/Route_World",
    };
  },
  computed: {
    ...mapGetters({
      getShowLocalities: "getShowLocalities",
      getSelectedSite: "getSelectedSite",
      selectedState: "getSelectedState",
      selectedIndicator: "getSelectedIndicator",
      selectedLocality: "getSelectedLocality",
    }),
    indicatorOutfields() {
      const schemes = [
        {
          name: "Población total",
          outFields: ["nom_ent", "nom_mun", "pobtot", "pobfem", "pobmas"],
        },
        {
          name: "Grado de marginación",
          outFields: [
            "nom_ent",
            "nom_mun",
            "gmm_20",
            "F_p15ym_an",
            "p15ym_an_f",
            "p15ym_an_m",
            "F_p15pri_in",
            "p15pri_inf",
            "p15pri_inm",
            "F_psinder",
            "psinder_f",
            "psinder_m",
            "F_pea",
            "F_pea_f",
            "F_pea_m",
            "F_pobreza",
            "graproes",
            "graproes_f",
            "graproes_m"
          ],
        },
        {
          name: "Población afromexicana, afrodescendiente y/o negra",
          outFields: [
            "nom_ent",
            "nom_mun",
            "pob_afro",
            "pob_afro_f",
            "pob_afro_m",
            "F_pob_afro",
          ],
        },
        {
          name: "Población que habla una lengua indígena",
          outFields: [
            "nom_ent",
            "nom_mun",
            "F__hli",
            "p3ym_hli",
            "p3ym_hli_f",
            "p3ym_hli_m",
            "p3hlinhe",
            "p3hlinhe_f",
            "p3hlinhe_m",
            "li1",
            "li2",
            "li3",
          ],
        },
        {
          name: "Autoadscripción indígena",
          outFields: ["nom_ent", "nom_mun", "F__aai", "aai_f", "aai_m", "F_hi"],
        },
        {
          name: "Municipios indígenas",
          outFields: [
            "nom_ent",
            "nom_mun",
            "mun_ind",
            "sn_ind",
            "tent",
            //"n_agrario",
          ],
        },
        {
          name: "Adolescentes",
          outFields: [
            "nom_ent",
            "nom_mun",
            "p_12_a_17",
            "p_12_a_17_m",
            "p_12_a_17_f",
            "hli__12_a_17",
            "hli__12_a_17_m",
            "hli__12_a_17_f",
            "F__hli_12_a_17",
            "afro_12_a_17",
            "afro_12_a_17_m",
            "afro_12_a_17_f",
            "F__afro_adol",
          ],
        },
      ];
      if (this.selectedIndicator) {
        let selectedScheme = schemes.find((scheme) => {
          return scheme.name === this.selectedIndicator;
        });
        return selectedScheme.outFields;
      } else {
        return [];
      }
    },
    indicatorOutfields2024() {
      const schemes = [
        {
          name: "Población total",
          outFields: ["nom_ent", "nom_mun", "p_12_a_17", "p_12_a_17_m", "p_12_a_17_h"],
        },
        {
          name: "Grado de marginación",
          outFields: [
            "nom_ent",
            "nom_mun",
            "graproes",
            'graproes_h',
            'graproes_m',
          ],
        },
        {
          name: "Población afromexicana, afrodescendiente y/o negra",
          outFields: [
            "nom_ent",
            "nom_mun",
            "afro_12_a_17",
            "afro_12_a_17_h",
            "afro_12_a_17_m",
            "F__afro_adol",
          ],
        },
        {
          name: "Población que habla una lengua indígena",
          outFields: [
            "nom_ent",
            "nom_mun",
            "hli__12_a_17",
            "hli__12_a_17_h",
            "hli__12_a_17_m",
            "F__hli_12_a_17",
          ],
        },
        {
          name: "Adolescentes",
          outFields: [
            "nom_ent",
            "nom_mun",
            "p_12_a_17",
            "p_12_a_17_m",
            "p_12_a_17_f",
            "hli__12_a_17",
            "hli__12_a_17_m",
            "hli__12_a_17_f",
            "F__hli_12_a_17",
            "afro_12_a_17",
            "afro_12_a_17_m",
            "afro_12_a_17_f",
            "F__afro_adol",
          ],
        },
      ];
      if (this.selectedIndicator) {
        let selectedScheme = schemes.find((scheme) => {
          return scheme.name === this.selectedIndicator;
        });
        return selectedScheme.outFields;
      } else {
        return [];
      }
    },
    indcatorRenderer() {
      const schemes = [
        {
          name: "Población total",
          renderer: {
            type: "class-breaks",
            field: "pobtot",
            legendOptions: {
              title: "Habitantes totales del municipio (habs)",
            },
            classBreakInfos: [
              {
                minValue: 1,
                maxValue: 999,
                symbol: this.levelOne,
                label: "1 - 99",
              },
              {
                minValue: 999,
                maxValue: 9999,
                symbol: this.levelTwo,
                label: "1,000 - 9,999",
              },
              {
                minValue: 9999,
                maxValue: 49999,
                symbol: this.levelThree,
                label: "10,000 - 49,999",
              },
              {
                minValue: 49999,
                maxValue: 99999,
                symbol: this.levelFour,
                label: "50,000 - 99,999",
              },
              {
                minValue: 99999,
                maxValue: 10000000,
                symbol: this.levelFive,
                label: "> 100,000",
              },
            ],
          },
        },
        {
          name: "Grado de marginación",
          renderer: {
            type: "unique-value", // autocasts as new UniqueValueRenderer()
            legendOptions: {
              title: "Grado de marginación",
            },
            field: "gmm_20",
            uniqueValueInfos: [
              {
                value: "Muy Alto",
                label: "Muy alto",
                symbol: this.levelFive,
              },

              {
                value: "Alto",
                label: "Alto",
                symbol: this.levelFour,
              },

              {
                value: "Medio",
                label: "Medio",
                symbol: this.levelThree,
              },

              {
                value: "Bajo",
                label: "Bajo",
                symbol: this.levelTwo,
              },

              {
                value: "Muy Bajo",
                label: "Muy bajo",
                symbol: this.levelOne,
              },
            ],
          },
        },
        {
          name: "Población afromexicana, afrodescendiente y/o negra",
          renderer: {
            type: "class-breaks",
            field: "pob_afro",
            legendOptions: {
              title: "Personas afromexicana, afrodescendiente y/o negra (habs)",
            },
            classBreakInfos: [
              {
                minValue: 0,
                maxValue: 0,
                symbol: this.levelOne,
                label: "0",
              },
              {
                minValue: 1,
                maxValue: 99,
                symbol: this.levelTwo,
                label: "1 - 99",
              },
              {
                minValue: 99,
                maxValue: 999,
                symbol: this.levelThree,
                label: "100 - 999",
              },
              {
                minValue: 999,
                maxValue: 4999,
                symbol: this.levelFour,
                label: "1,000 - 4,999",
              },
              {
                minValue: 4999,
                maxValue: 5000000,
                symbol: this.levelFive,
                label: "> 5,000",
              },
            ],
          },
        },
        {
          name: "Población que habla una lengua indígena",
          renderer: {
            type: "class-breaks",
            field: "F__hli",
            legendOptions: {
              title: "Porcentaje de habitantes que hablan lengua indígena (%)",
            },
            classBreakInfos: [
              {
                minValue: 0,
                maxValue: 20,
                symbol: this.levelOne,
                label: "0 - 20",
              },
              {
                minValue: 20,
                maxValue: 40,
                symbol: this.levelTwo,
                label: "20 - 40",
              },
              {
                minValue: 40,
                maxValue: 60,
                symbol: this.levelThree,
                label: "40 - 60",
              },
              {
                minValue: 60,
                maxValue: 80,
                symbol: this.levelFour,
                label: "60 - 80",
              },
              {
                minValue: 80,
                maxValue: 100,
                symbol: this.levelFive,
                label: "80 - 100",
              },
            ],
          },
        },
        {
          name: "Autoadscripción indígena",
          renderer: {
            type: "class-breaks",
            field: "F__aai",
            legendOptions: {
              title: "Porcentaje de habitantes con autoadscripción indígena(%)",
            },
            classBreakInfos: [
              {
                minValue: 0,
                maxValue: 20,
                symbol: this.levelOne,
                label: "0 - 20",
              },
              {
                minValue: 20,
                maxValue: 40,
                symbol: this.levelTwo,
                label: "20 - 40",
              },
              {
                minValue: 40,
                maxValue: 60,
                symbol: this.levelThree,
                label: "40 - 60",
              },
              {
                minValue: 60,
                maxValue: 80,
                symbol: this.levelFour,
                label: "60 - 80",
              },
              {
                minValue: 80,
                maxValue: 100,
                symbol: this.levelFive,
                label: "80 - 100",
              },
            ],
          },
        },
        {
          name: "Municipios indígenas",
          renderer: {
            type: "unique-value", //autocasts as new UniqueValueRenderer()
            legendOptions: {
              title: "Tipo de municipio",
            },
            field: "mun_ind",
            uniqueValueInfos: [
              {
                value: "Municipio indígena",
                label: "Municipio indígena",
                symbol: this.levelFive,
              },

              {
                value: "Mpio. con presencia indígena",
                label: "Municipio con presencia indígena",
                symbol: this.levelThree,
              },
              {
                value: "Mpio. con población indígena dispersa",
                label: "Mpio. con población indígena dispersa",
                symbol: this.levelTwo,
              },
              {
                value: "Sin población indígena",
                label: "Sin población indígena",
                symbol: this.levelOne,
              },
            ],
          },
        },
        {
          name: "Adolescentes",
          renderer: {
            type: "class-breaks",
            field: "p_12_a_17",
            legendOptions: {
              title: "Adolescentes totales del municipio (habs)",
            },
            classBreakInfos: [
              {
                minValue: 1,
                maxValue: 999,
                symbol: this.levelOne,
                label: "1 - 999",
              },
              {
                minValue: 1000,
                maxValue: 4999,
                symbol: this.levelTwo,
                label: "1,000 – 4,999",
              },
              {
                minValue: 5000,
                maxValue: 9999,
                symbol: this.levelThree,
                label: "5,000 – 9,999",
              },
              {
                minValue: 10000,
                maxValue: 49999,
                symbol: this.levelFour,
                label: "10,000 – 49,999",
              },
             {
                minValue: 50000,
                maxValue: 10000000,
                symbol: this.levelFive,
                label: "50,000 – 99,999",
              }
            ],
          },
        },
      ];
      if (this.selectedIndicator) {
        let selectedScheme = schemes.find((scheme) => {
          return scheme.name === this.selectedIndicator;
        });
        return selectedScheme.renderer;
      } else {
        return {};
      }
    },
  },
  watch: {
    selectedLocality(val) {
      if (val !== null) {
        this.addGraphic("origin", val);
        if (this.selectedIndicator) {
          this.queryIndcators();
          if (this.selectedIndicator === "Municipios indígenas") {
            this.queryNucleos();
          }
        }
      }
    },
    selectedIndicator(val) {
      if (val !== null) {
        this.queryNucleos();
        this.queryIndcators();
        this.renderHeatMap();
      } else {
        this.indicatorsLayer.visible = false;
      }
    },
    getSelectedSite(val) {
      this.createPointsLocalitiesLayer(val);
      this.createSitesLayerLayer(val);
      this.querySites(this.selectedState);
    },
    getShowLocalities(val) {
      if (this.myPointsLocalities) {
        this.myPointsLocalities.visible = val;
      }
      if (this.mySitesLayer) {
        this.mySitesLayer.visible = val;
      }
    },
    selectedState(newVal) {
      if (newVal == "Oaxaca") {
        this.view.center = [-95.760907, 17.1167194];
        this.view.zoom = 6;
      } else if (newVal == "Campeche") {
        this.view.center = [-90.3, 18.9930458];
        this.view.zoom = 7;
      } else if (newVal == "Yucatán") {
        this.view.center = [-88.6370239, 20.6228265];
        this.view.zoom = 7;
      }
      this.querySites(newVal);
    },
  },
  mounted() {
    this.loadMap();
    const self = this;
    this.$root.$on("lawSiteSelected", (data) => {
      self.addGraphic("destination", data);
    });
    this.$root.$on("search-loc", (data) => {
      self.localitySearch(self.selectedState, data);
    });
    this.$root.$on("changedSelectedState", () => {
      if (self.view.graphics.length > 0) {
        self.view.graphics.removeAll();
        self.$store.dispatch("setRouteData", null);
      }
    });
    this.$root.$on("changeShowLegends", (data) => {
      self.legend.visible = data;
    });
  },
  methods: {
    async queryNucleos() {
      if (this.selectedIndicator === "Municipios indígenas") {
        let queryAgrarios = this.nucleosAgrarios.createQuery();
        queryAgrarios.where = `ENTIDAD = '${this.selectedLocality.ent
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()}' AND MUNICIPIO = '${this.selectedLocality.mun
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()}'`;
        queryAgrarios.outFields = ["ENTIDAD", "MUNICIPIO", "N_AGRARIO"];
        await this.nucleosAgrarios
          .queryFeatures(queryAgrarios)
          .then((res) => {
            let nucleosArray = res.features.map((n) => {
              return n.attributes.N_AGRARIO;
            });
            this.$store.dispatch("setnAgrarios", nucleosArray);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    async queryIndcators() {
      if (this.indicatorsLayer && this.selectedLocality) {
        let query = this.indicatorsLayer.createQuery();
        query.where = `nom_ent = '${this.selectedLocality.ent}' AND nom_mun = '${this.selectedLocality.mun}'`;
        query.outFields = this.indicatorOutfields;

        await this.indicatorsLayer
          .queryFeatures(query)
          .then((res) => {
            const data = res.features[0].attributes;
            this.$store.dispatch("setMunData", data);
          })
          .catch((error) => {
            console.error(error);
          });
          
          /* /** 
           * Query for new indicators in 2024
           * @TODO - do not trigger for all indicators
          query = this.indicatorsLayer2024.createQuery();
          query.where = `nom_ent = '${this.selectedLocality.ent}' AND nom_mun = '${this.selectedLocality.mun}'`;
          query.outFields = this.indicatorOutfields2024;

          await this.indicatorsLayer2024
          .queryFeatures(query)
          .then((res) => {
            const data = res.features[0].attributes;
            console.log('this.indicatorsLayer2024',data)
            this.$store.dispatch("setMunData2024", data);
          })
          .catch((error) => {
            console.error(error);
          }); */
      }
    },
    // should be called whe selectedIndicator change
    renderHeatMap() {
      let layer = this.indicatorsLayer;
      layer.popupTemplate = {
        title: "Municipio: {nom_mun}",
        content: [
          {
            type: "fields",
            fieldInfos: [
              {
                fieldName: this.indcatorRenderer.field,
                label: this.selectedIndicator,
              },
            ],
          },
        ],
      };
      layer.renderer = this.indcatorRenderer;
      layer.visible = true;
    },

    async loadMap() {
      await loadModules(
        [
          "esri/config",
          "esri/Map",
          "esri/views/MapView",
          "esri/layers/FeatureLayer",
          "esri/smartMapping/renderers/color",
          "esri/widgets/Legend",
        ],
        {
          css: true,
        }
      ).then(
        ([
          esriConfig,
          Map,
          MapView,
          FeatureLayer,
          colorRendererCreator,
          Legend,
        ]) => {
          // esriConfig.apiKey ="AAPK35ff7d04e02645dbbe30e574cb2416b8Zgv-0wQ5T6YGj6jHc8ow0mw3YqSohBi-k3i9vH9gFYCZe1AcbERhLnQyt0DC2N_N";
          this.FeatureLayer = FeatureLayer;
          this.colorRendererCreator = colorRendererCreator;
          this.searchLocalityLayer = new FeatureLayer({
            title: "Localidades",
            url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/locality_search__mas_qro_05052024___locality_search/FeatureServer"
            //url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/locality_search/FeatureServer/0",
          });
          this.indicatorsLayer = new FeatureLayer({
            title: "Municipios",
            url:  "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/joinMun20gwWithJoinD4NuevosIndicadoresYQro_09_07_24/FeatureServer",
            // url:"https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/joinMun20gwWithJoinD4NuevosIndicadoresYQro_10_06_24/FeatureServer/0",
            //url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/mun_data_join_new_indicators_qro_05062024/FeatureServer",
            //url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/mun_data_shapes200122/FeatureServer",
            visible: false,
            opacity:1,
          });
          /* this.indicatorsLayer2024 = new FeatureLayer({
            title: "Municipios",
            url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/joinD4NuevosIndicadores_29_05_2024/FeatureServer",
            visible: false,
          }); */
          this.nucleosAgrarios = new FeatureLayer({
            title: "Nucleos Agrarios",
            url: "https://services5.arcgis.com/LZ4OoSCijnlgkZex/arcgis/rest/services/nucleos_agrarios/FeatureServer/0",
          });

          this.map = new Map({
            basemap: "dark-gray-vector",
          });
          this.map.add(this.indicatorsLayer);
          this.view = new MapView({
            container: "viewDiv",
            map: this.map,
            center: [-90.2, 18.9930458],
            zoom: 7,
            highlightOptions: {
              color: "orange",
            },
            constraints: {
              minZoom: 6,
            },
          });
          this.legend = new Legend({
            view: this.view,
            visible: true,
          });
          //ui legend display for view in map
          this.view.ui.add(this.legend, {
            position: "bottom-left",
            index: 0,
          });
        }
      );
    },
    createPointsLocalitiesLayer(selectedSite) {
      console.log('selectedSite', selectedSite)
      this.map.remove(this.myPointsLocalities);
      if(selectedSite === 'Justicia para adolescentes' || selectedSite === 'Juzgados de atención a población indígena') return false 
      let item = this.myPointsLocalitiesSchemes.find((scheme) => {
        return scheme.site === selectedSite;
      });
     
      let def ={
        title: "Localidades",
        url: item.url,
        popupTemplate: {
          title: item.popupTitle,
        },
        // definitionExpression: `nom_ent = '${this.selectedState}'`,
      }
      if(item.renderer){
        def.renderer = item.renderer
      }
      this.myPointsLocalities = new this.FeatureLayer(def);
      this.map.add(this.myPointsLocalities);
    },
    createSitesLayerLayer(selectedSite) {
      let item = this.mySitesLayerSchemes.find((scheme) => {
        return scheme.title === selectedSite;
      });
      if (this.mySitesLayer) this.map.remove(this.mySitesLayer);
      let def = {
        title: item.title,
        url: item.url,
        popupTemplate: {
          title: item.popupTitle,
        },
        definitionExpression: item.definitionExpression,
      }
      if(item.renderer){
        def.renderer = item.renderer
      }
      this.mySitesLayer = new this.FeatureLayer(def);

     
      this.map.add(this.mySitesLayer);
    },
    async localitySearch(stateName, searchVal = "") {
      if (this.searchLocalityLayer) {
        if (this.runningQuery) return;
        this.runningQuery = true;
        let query = this.searchLocalityLayer.createQuery();
        let result = null;
        if (stateName !== "Todo") {
          query.where =
            searchVal === ""
              ? `nom_ent = '${stateName}'`
              : `nom_ent = '${stateName}' AND (nom_mun LIKE '%${searchVal}%' OR nom_loc LIKE '%${searchVal}%')`;
        } else {
          query.where = `nom_mun LIKE '%${searchVal}%' OR nom_loc LIKE '%${searchVal}%'`;
        }
        query.outFields = [
          "nom_loc",
          "nom_mun",
          "nom_ent",
          "lon_decimal",
          "lat_decimal",
        ];
        await this.searchLocalityLayer
          .queryFeatures(query)
          .then((results) => {
            result = results.features;
            let queryArr = result.map((locArray) => {
              return locArray;
            });
            let localities = queryArr.map((loc) => {
              return {
                text: `${loc.attributes.nom_loc}, ${loc.attributes.nom_mun}`,
                loc: loc.attributes.nom_loc,
                mun: loc.attributes.nom_mun,
                ent: loc.attributes.nom_ent,
                lon: loc.attributes.lon_decimal,
                lat: loc.attributes.lat_decimal,
              };
            });
            localities.sort(function (a, b) {
              var nameA = a.loc.toUpperCase();
              var nameB = b.loc.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }

              // names must be equal
              return 0;
            });
            this.$store.dispatch("setSearchedLocalities", localities);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => (this.runningQuery = false));
      }
    },
    async querySites(stateName) {
      if (this.mySitesLayer && stateName !== null) {
        let result = null;
        let query = this.mySitesLayer.createQuery();
        if (this.getSelectedSite !== "Centros penitenciarios" && this.getSelectedSite !== "Justicia para adolescentes") {
          if (this.getSelectedSite === "Ministerios públicos federales") {
            query.where = `nom_ent = '${stateName}' AND categoria = 'Ministerio Público Federal'`;
            query.outFields = [
              "categoria",
              "tipo",
              "localidad",
              "lon_decimal",
              "lat_decimal",
            ];
          } else if (
            this.getSelectedSite === "Ministerios públicos estatales"
          ) {
            query.where = `nom_ent = '${stateName}' AND categoria = 'Agencia del Ministerio Público de la Entidad'`;
            query.outFields = [
              "categoria",
              "tipo",
              "localidad",
              "lon_decimal",
              "lat_decimal",
            ];
          } else if (
            this.getSelectedSite === "Tribunales y juzgados estatales"
          ) {
            query.where = `nom_ent = '${stateName}' AND categoria = 'Tribunal o Juzgado del Poder Judicial de Estado'`;
            query.outFields = [
              "categoria",
              "localidad",
              "tipo_uno",
              "tipo_dos",
              "lon_decimal",
              "lat_decimal",
            ];
          } else if (
            this.getSelectedSite === "Tribunales y juzgados federales"
          ) {
            query.where = `nom_ent = '${stateName}' AND categoria = 'Tribunal o Juzgado del Poder Judicial de la Federación'`;
            query.outFields = [
              "categoria",
              "localidad",
              "tipo_uno",
              "tipo_dos",
              "lon_decimal",
              "lat_decimal",
            ];
          }
        } else {
          query.where = `nom_ent = '${stateName}'`;
          query.outFields = [
            "categoria",
            "localidad",
            "lon_decimal",
            "lat_decimal",
          ];
        }

        console.log(query.where)
        console.log(query.outFields)

        const siteType = this.getSelectedSite;
        await this.mySitesLayer
          .queryFeatures(query)
          .then((results) => {
            switch (siteType) {
              case "Centros penitenciarios":
                result = results.features.map((site) => {
                  return {
                    loc: site.attributes.localidad,
                    cat: site.attributes.categoria,
                    lon: site.attributes.lon_decimal,
                    lat: site.attributes.lat_decimal,
                    text: `${site.attributes.categoria}, ${site.attributes.localidad}`,
                  };
                });
                break;
              case "Ministerios públicos federales":
                result = results.features.map((site) => {
                  return {
                    loc: site.attributes.localidad,
                    cat: site.attributes.categoria,
                    lon: site.attributes.lon_decimal,
                    lat: site.attributes.lat_decimal,
                    text: `${site.attributes.tipo}, ${site.attributes.localidad}`,
                  };
                });
                break;
              case "Ministerios públicos estatales":
                result = results.features.map((site) => {
                  return {
                    loc: site.attributes.localidad,
                    cat: site.attributes.categoria,
                    lon: site.attributes.lon_decimal,
                    lat: site.attributes.lat_decimal,
                    text: `${site.attributes.tipo}, ${site.attributes.localidad}`,
                  };
                });
                break;
                case "Justicia para adolescentes":
                result = results.features.map((site) => {
                  return {
                    loc: site.attributes.localidad,
                    cat: site.attributes.categoria,
                    lon: site.attributes.lon_decimal,
                    lat: site.attributes.lat_decimal,
                    text: `${site.attributes.categoria}, ${site.attributes.localidad}`,
                  };
                });
                break;
              default:
                result = results.features.map((site) => {
                  return {
                    loc: site.attributes.localidad,
                    cat: site.attributes.categoria,
                    lon: site.attributes.lon_decimal,
                    lat: site.attributes.lat_decimal,
                    text: site.attributes.tipo_dos
                      ? `${site.attributes.tipo_uno}, ${site.attributes.tipo_dos}, ${site.attributes.localidad}`
                      : `${site.attributes.tipo_uno}, ${site.attributes.localidad}`,
                  };
                });
                break;
            }
          })
          .catch((error) => {
            console.error(error);
          });
        if (result) {
          result.sort(function (a, b) {
            var nameA = a.text.toUpperCase();
            var nameB = b.text.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
          this.$store.dispatch("setSites", result);
        }
      }
    },
    async addGraphic(type, point) {
      const self = this;
      if (this.view.graphics.length > 0) {
        let pointsArr = self.view.graphics.items;
        pointsArr.forEach((point) => {
          if (point.attributes.type === type) {
            self.view.graphics.remove(self[`${type}Point`]);
          }
        });
      }
      let geometryPayload = {
        type: "point",
        longitude: point.lon,
        latitude: point.lat,
      };
      await loadModules(["esri/Graphic"]).then(([Graphic]) => {
        if (type === "origin") {
          this.originPoint = new Graphic({
            symbol: {
              type: "simple-marker",
              color: "white",
              size: "10px",
            },
            geometry: geometryPayload,
            attributes: { type: "origin" },
          });
          this.view.graphics.add(this.originPoint);
        } else if (type === "destination") {
          this.destinationPoint = new Graphic({
            symbol: {
              type: "simple-marker",
              color: "black",
              size: "10px",
            },
            geometry: geometryPayload,
            attributes: { type: "destination" },
          });
          this.view.graphics.add(this.destinationPoint);
        }
      });
      if (this.destinationPoint !== null && this.originPoint !== null) {
        this.getRoute();
      }
    },
    async showSearchedLocality(point) {
      const self = this;
      let geometryPayload = {
        type: "point",
        longitude: point.lon,
        latitude: point.lat,
      };
      await loadModules(["esri/Graphic"]).then(([Graphic]) => {
        self.searchedPoint = new Graphic({
          symbol: {
            type: "simple-marker",
            color: "white",
            size: "10px",
          },
          geometry: geometryPayload,
        });
        self.view.graphics.add(self.searchedPoint);
      });
      this.view.center = [point.lon, point.lat];
      this.view.zoom = 10;
    },
    async getRoute() {
/* 
      fetch("https://route-api.arcgis.com/arcgis/rest/services/World/Route/NAServer/Route_World/solve", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer AAPTxy8BH1VEsoebNVZXo8HurAVQZ6-LnYRMOzOaXYMMN1VUqr3iNNujAqRXgaPD0Y9ccV_L87SyygXAH-HlBrkYA8_fb2saNN9eyJzp6xSxNAylhlolXm2qF7B7F0hpFVBMh4TZ7mrcw2J4sw5ZNrpOtB8bZybR0HsOf5DgpwymP5lPw-8HwYMlR7I9RdX-oW3L58aXRJYyrkwFVu8c256JmwpZTWt6UM7UWpgmGjnzVVLBSmi_4SpMjpXYPjrenmNfAT1_apiQ7qiA",
        },
        body: JSON.stringify({
          stops: "{\"features\": [{ \"geometry\": {\"x\": -90.184986, \"y\": 19.579982 }}, { \"geometry\": {\"x\": -91.83645, \"y\": 18.637789 }}]}",
          f: "json",
        }),
      })
        .then((response) => console.log(response.json()))
        .then((data) => console.log(data))
        .catch((error) => console.error("Error accessing service:", error)); */


      const self = this;
      if (this.route !== null) {
        this.view.graphics.remove(this.route);
      }
      this.$store.dispatch("toggleLoadingRoute");
      await loadModules([
        "esri/rest/route",
        "esri/rest/support/RouteParameters",
        "esri/rest/support/FeatureSet",
      ]).then(([route, RouteParameters, FeatureSet]) => {
        const routeParams = new RouteParameters({
          apiKey:"AAPTxy8BH1VEsoebNVZXo8HurAVQZ6-LnYRMOzOaXYMMN1Us8PXNr9ozya77fTxg3FXI7z-hk_5GNDfpSdgZoY4zbSd82EyPUbd-WndmQPaq5n_5ob-BCUfuYl-Ze-9IQiegm_8RfhxVmw-pij5SJuIAA7pea4AYO2ItbDSykobIMstawP8_Rp2mh4lQdC0xnrvyiD8qqClINxpPl_fjeOIY-BtqTFTfJa3HR1dmsjgwk4WDeo8mFhGY4MWJyJLV_wniAT1_apiQ7qiA",
          stops: new FeatureSet({
            features: self.view.graphics.toArray(),
          }),
        });
        console.log(route);
        console.log(this.routeUrl,  routeParams);
        route.solve(this.routeUrl, routeParams).then(function (data) {
          data.routeResults.forEach(function (result) {
            const routeData = {
              km: result.route.attributes.Total_Kilometers.toFixed(2),
              time: minutesToHours(
                result.route.attributes.Total_TravelTime.toFixed(2)
              ),
            };
            result.route.symbol = {
              type: "simple-line",
              color: [5, 150, 255],
              width: 3,
            };
            self.route = result.route;
            self.view.graphics.add(self.route);
            self.$store.dispatch("setRouteData", routeData);
          });
        });
      });
      this.$store.dispatch("toggleLoadingRoute");
    },
  },
};
</script>

<style>
/* esri styles */
/* @import url('https://js.arcgis.com/4.4/esri/themes/light/main.css'); */

#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
#viewDiv {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.localities {
  display: none;
}
h1,
.h1 {
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
}
h2,
.h2 {
  font-size: 10px;
  font-family: "Open Sans", sans-serif;
}
h3,
.h3 {
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
}
h4,
.h4 {
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: 200;
}
div.esri-legend__message {
  display: none;
}
div.esriLegendMsg {
  display: none;
}
.esriLegendMsg {
  visibility: hidden;
}
.esri-widget h1,
.esri-widget h3,
.esri-widget h4,
.esri-widget h5,
.esri-widget h6 {
  font-weight: 600;
  margin: 0 0 0.5rem 0;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  margin-left: 10px;
}
.esri-widget h2 {
  font-weight: 500;
  margin: 0 0 0.5rem 0;
  font-size: 13.5px;
  margin-left: 10px;
  font-family: "Open Sans", sans-serif;
}
.esri-legend__layer-caption {
  display: table-caption;
  padding: 6px 0;
  font-family: "Open Sans", sans-serif;
  margin-left: 10px;
}
.esri-widget table tr th {
  width: 50%;
  text-align: left;
  border-right: 3px solid rgba(0, 0, 0, 0.05);
  font-family: "Open Sans", sans-serif;
}
.esri-legend__layer-table--size-ramp .esri-legend__layer-cell {
  display: table-cell;
  font-family: "Open Sans", sans-serif;
}
.esri-widget table tr td {
  width: 50%;
  font-family: "Open Sans", sans-serif;
}
</style>